import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

// DO NOT ADD VALUES AT THE BEGINNING AS ENHERTU IS USING 0,1,2 as VALUES
export enum SpecialMedAdditionalInformation {
  // eslint-disable-next-line no-unused-vars
  NONE,
  // eslint-disable-next-line no-unused-vars
  ENHERTU_HER_LOW,
  // eslint-disable-next-line no-unused-vars
  ENHERTU_HER_POS,
  // eslint-disable-next-line no-unused-vars
  BRUKINSA_PREVIOUSLY_TREATED,
  // eslint-disable-next-line no-unused-vars
  BRUKINSA_UNTREATED,
  // eslint-disable-next-line no-unused-vars
  IMFINZI_TOPAZ,
  // eslint-disable-next-line no-unused-vars
  IMBRUVICA,
  // eslint-disable-next-line no-unused-vars
  IMJUDO_HIMALAYA,
  // eslint-disable-next-line no-unused-vars
  IMJUDO_BLEEDING_RISK,
  // eslint-disable-next-line no-unused-vars
  IMFINZI_AEGEN,
    // eslint-disable-next-line no-unused-vars
  TAGRISSO,
    // eslint-disable-next-line no-unused-vars
  TAGRISSO_FLAURA,
    // eslint-disable-next-line no-unused-vars
  TAGRISSO_FLAURA2,
      // eslint-disable-next-line no-unused-vars
  TAGRISSO_ADAURA,
   // eslint-disable-next-line no-unused-vars
  LYNPARZA_OLYMPIA,
   // eslint-disable-next-line no-unused-vars
  LYNPARZA_PAOLA,
   // eslint-disable-next-line no-unused-vars
  LYNPARZA_SOLO,
  // eslint-disable-next-line no-unused-vars
  IMJUDO_POSEIDON,
  // eslint-disable-next-line no-unused-vars
  IBRANCE,
  // eslint-disable-next-line no-unused-vars
  SOMATULINE_CLARINET,
  // eslint-disable-next-line no-unused-vars
  CABOMETYX_1,
  // eslint-disable-next-line no-unused-vars
  CABOMETYX_2,
  // eslint-disable-next-line no-unused-vars
  CABOMETYX_3,
  // eslint-disable-next-line no-unused-vars
  CABOMETYX_4,
  // eslint-disable-next-line no-unused-vars
  CABOMETYX_5,
  // eslint-disable-next-line no-unused-vars
  CABOMETYX_6,
  // eslint-disable-next-line no-unused-vars
  IMFINZI_ADREIATIC,
  // eslint-disable-next-line no-unused-vars
  SPRAVATO,
  // eslint-disable-next-line no-unused-vars
  TECVAYLI,
  // eslint-disable-next-line no-unused-vars
  TALVEY,
  // eslint-disable-next-line no-unused-vars
  DARZALEX,
  // eslint-disable-next-line no-unused-vars
  TUKYSA,
}

export interface ISpecialMedState {
  formData:{
    special_med_type:string;
    special_med_data: object| null,
    special_med_additional_information: SpecialMedAdditionalInformation
  };
}
const initialState:ISpecialMedState = {
  formData: {
    special_med_type: "",
    special_med_data: null,
    special_med_additional_information: SpecialMedAdditionalInformation.NONE,
  },
};

const specialMedSlice = createSlice({
  name: "specialMed",
  initialState,
  reducers: {
    onChangeSpecialMedValue: (state, {payload}:PayloadAction<{field:string;value:any;}>)=>{
      state.formData[payload.field] = payload.value;
    },
    onClearSpecialMedState: (state)=>{
      state.formData=initialState.formData;
    },
    onReceiveSpecialMed: (state, {payload}:PayloadAction<any>)=>{
      console.debug("onReceiveSpecialMed", payload);
      state.formData ={...state.formData, ...payload};
    },
  },
});

export const {
  onChangeSpecialMedValue,
  onClearSpecialMedState,
  onReceiveSpecialMed,
} = specialMedSlice.actions;

export default specialMedSlice.reducer;
export const selectSpecialMedType = (state:RootState) =>
  state.wizard.medicine.additionalInformation.specialMed.formData.special_med_type;
export const selectSpecialAdditionalInformation = (state:RootState) =>
  state.wizard.medicine.additionalInformation.specialMed.formData.special_med_additional_information;

