import {countries} from "components/formUtils";
import {initialState as patientInitialState} from "./forms/patient/patientSlice";
import i18n from "i18n";

export const formatReceivedDoctorData = (data:any)=>({
  doctor_title: data.doctor_title,
  doctor_surename: data.doctor_surename || "",
  doctor_lastname: data.doctor_lastname || "",
  doctor_phone_no: data.doctor_phone_no,
  doctor_fax_no: data.doctor_fax_no,
  doctor_email_addr: data.doctor_email_addr,
  doctor_center_name: data.doctor_center_name,
  doctor_specialty: data.doctor_specialty,
  doctor_street: data.doctor_street,
  doctor_postalcode: data.doctor_postalcode || "",
  doctor_city: data.doctor_city,
  doctor_country: findCountryObjectByCode(data.doctor_country),
});
export const formatReceivedPatientData = (data:any)=>({
  patient_surename: data.patient_surename || "",
  patient_lastname: data.patient_lastname || "",
  patient_gender: data.patient_gender,
  patient_date_of_birth: data.patient_date_of_birth,
  patient_phone_no: data.patient_phone_no,
  patient_email_addr: data.patient_email_addr,
  patient_insurance_no: data.patient_insurance_no || "",
  patient_street: data.patient_street,
  patient_postalcode: data.patient_postalcode,
  patient_city: data.patient_city,
  patient_country: findCountryObjectByCode(data.patient_country),
  patient_insurance: {
    ...patientInitialState.formData.patient_insurance || "",
    DESCR1: data.patient_insurance_company || "",
    GLN: data.patient_insurance_company_gln || "",
  },
  patient_agreement: data.patient_agreement===1,
});

export const formatReceivedDiagnosisData = (data:any)=>({
  diagnosis: data.diagnosis,
  remarks_diagnosis: data.remarks_diagnosis,
  urgent: data.urgent,
});
export const formatReceivedDosisPlanData = (data:any)=>({
  patient_treatment_startdate: new Date(data.patient_treatment_startdate),
  dosis: data.dosis,
  combined_drug: data.combined_drug?.length>0? data.combined_drug.split(","):[],
});
export const formatReceivedTreatmentPlanData = (data:any)=>({
  limitatio_approval: data.med_data?.limitatio_approval==="true",
  specialist_details: data.med_data?.specialist_details==="true",
  specialist_info: data.med_data?.specialist_info==="true",
});
export const formatReceivedMedData = (data:any)=>({
  required_drug: data.required_drug,
  request_type: data.request_type,
  pharma_email: data.pharma_email || data.med_data?.pharma_email,
  pharma_company_name: data.pharma_company_name,
  required_drug_name: data.required_drug_name,
  kn_id: data.kn_id,
  article_prdno: data.med_data?.article_prdno,
});

export const findCountryObjectByCode=(code:string)=>{
  return countries.find((e)=>(e.code===code||e.label===code)&&
  e.lang===i18n.language) || patientInitialState.formData.patient_country;
};

export const formatReceivedPraluentData = (data:any)=>({
  ersteverordnung: data.med_data?.praluent_data?.ersteverordnung,
  pcsk: data.med_data?.praluent_data?.pcsk,
  ldlCWert_before: data.med_data?.praluent_data?.ldlCWert_before,
  praluent_measurement_date_before: data.med_data?.praluent_data?.praluent_measurement_date_before,
  ldlc_26: data.med_data?.praluent_data?.ldlc_26,
  ldlc_50: data.med_data?.praluent_data?.ldlc_50,
  ldlc_45: data.med_data?.praluent_data?.ldlc_45,
  diabetes: data.med_data?.praluent_data?.diabetes,
  increased_lipoprotein: data.med_data?.praluent_data?.increased_lipoprotein,
  hypertension: data.med_data?.praluent_data?.hypertension,
  treatment_diet: data.med_data?.praluent_data?.treatment_diet,
  treatment_statins: data.med_data?.praluent_data?.treatment_statins,
  treatment_ezetimibe: data.med_data?.praluent_data?.treatment_ezetimibe,
  statin1: data.med_data?.praluent_data?.statin1,
  myalgia_statin1: data.med_data?.praluent_data?.myalgia_statin1,
  rise_ck: data.med_data?.praluent_data?.rise_ck,
  severe_hepatopathy: data.med_data?.praluent_data?.severe_hepatopathy,
  statin2: data.med_data?.praluent_data?.statin2,
  myalgia_statin2: data.med_data?.praluent_data?.myalgia_statin2,
  blood_preasure: data.med_data?.praluent_data?.blood_preasure,
  hba1c: data.med_data?.praluent_data?.hba1c,
  nicotine_abstinence: data.med_data?.praluent_data?.nicotine_abstinence,
  ldlCWert_during: data.med_data?.praluent_data?.ldlCWert_during,
  praluent_measurement_date_during: data.med_data?.praluent_data?.praluent_measurement_date_during,
  ldlc_reduction: data.med_data?.praluent_data?.ldlc_reduction,
  value_reached: data.med_data?.praluent_data?.value_reached,
});
export const formatReceivedBrukinsaData = (data:any)=>({
  wanlenstrom: data.med_data?.brukinsa_data?.wanlenstrom,
  rituximab: data.med_data?.brukinsa_data?.rituximab,
  rituximabReason: data.med_data?.brukinsa_data?.rituximabReason,
  hasTreatment: data.med_data?.brukinsa_data?.hasTreatment,
  treatmentStart: data.med_data?.brukinsa_data?.treatmentStart,
  treatmentEnd: data.med_data?.brukinsa_data?.treatmentEnd,
});
export const formatReceivedDefaultMedicineData = (data:any)=>({
  pretreatment: data.med_data?.pretreatment,
  patient_condition: data.med_data?.patient_condition,
  disease_prognosis: data.med_data?.disease_prognosis,
  therapy_alternatives: data.med_data?.therapy_alternatives,
});
export const formatReceivedEnhertuData = (data:any)=>({
  formData: data.med_data?.enhertu_data,
});

export const formatReceivedQuviviqData = (data:any)=>({
  formData: data.med_data?.quviviq_data,
});
export const formatReceivedCalquenceData = (data:any)=>({
  formData: data.med_data?.calquence_data,
});
export const formatReceivedImfinziData = (data:any)=>({
  formData: data.med_data?.imfinzi_data,
});
export const formatReceivedImjudoData = (data:any)=>({
  formData: data.med_data?.imjudo_data,
});
export const formatReceivedTagrissoData = (data:any)=>({
  formData: data.med_data?.tagrisso_data,
});
export const formatReceivedImbruvicaData = (data:any)=>({
  formData: data.med_data?.imbruvica_data,
});
export const formatReceivedLynparzaData = (data:any)=>({
  formData: data.med_data?.lynparza_data,
});
export const formatReceivedIbranceData = (data:any)=>({
  formData: data.med_data?.ibrance_data,
});
export const formatReceivedSomatulineData = (data:any)=>({
  formData: data.med_data?.somatuline_data,
});
export const formatReceivedCabometyxData = (data:any)=>({
  formData: data.med_data?.cabometyx_data,
});
export const formatReceivedSpravatoData = (data:any)=>({
  formData: data.med_data?.spravato_data,
});
export const formatReceivedTecvayliData = (data:any)=>({
  formData: data.med_data?.tecvayli_data,
});
export const formatReceivedTalveyData = (data:any)=>({
  formData: data.med_data?.talvey_data,
});
export const formatReceivedDarzalexData = (data:any)=>({
  formData: data.med_data?.darzalex_data,
});
export const formatReceivedTukysaData = (data:any)=>({
  formData: data.med_data?.tukysa_data,
});

export const formatspecialMed = (data:any)=>({
  special_med_additional_information: data.med_data?.special_med_additional_information,
  special_med_data: data.med_data?.special_med_data,
  special_med_type: data.med_data?.special_med_type,
});
