import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

const fieldNames = [
  // Options
  "options_isIv",
  "options_isIc",


];

export interface TukysaState {
  formData: {
    // default stuff
    preTreatment: string
    patientCondition: string,
    prognosis: string,
    reasonsAlternativeTherapiesFailed: string,


  }
}

const initialState:TukysaState = {
  formData: {
  // default stuff
    preTreatment: "",
    patientCondition: "",
    prognosis: "",
    reasonsAlternativeTherapiesFailed: "",


  },
};

const TukysaFormSlice = createSlice({
  name: "Tukysa",
  initialState,
  reducers: {
    onClearTukysaState: (state)=>{
      state.formData = initialState.formData;
    },
    onReceiveSpecialMedTukysaEditData: (state, {payload}: PayloadAction<any>) => {
      updatestate(payload, state, true);
      if (payload.formData) {
        state.formData.preTreatment =
          payload.formData?.preTreatment;
        state.formData.patientCondition =
          payload.formData?.patientCondition;
        state.formData.reasonsAlternativeTherapiesFailed =
          payload.formData?.reasonsAlternativeTherapiesFailed;
        state.formData.prognosis =
          payload.formData?.prognosis;
      }
    },
    onChangeTukysaValue: (state, {payload}: PayloadAction<any>) => {
      console.debug("onChangeTukysaValue", payload);
      if (payload.preTreatment || payload.preTreatment === "") state.formData.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.patientCondition = payload.patientCondition;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;
      if (payload.prognosis || payload.prognosis === "") state.formData.prognosis = payload.prognosis;


      updatestate(payload, state, false);
    },
    onAddMedication: (state, action) => {
    },
    onRemoveMedication: (state, action) => {
    },
  },
});


export const {
  onReceiveSpecialMedTukysaEditData,
  onClearTukysaState,
  onChangeTukysaValue,
  onAddMedication,
  onRemoveMedication,
} = TukysaFormSlice.actions;

export default TukysaFormSlice.reducer;
export const selectTukysaValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.tukysaForm.formData;


function updatestate(payload: any, state, isEdit: boolean) {
  const data = payload.formData;
  console.debug("data", JSON.stringify(data));
  if (!data) return;

  if (data.medications) {
    state.formData.medications = data.medications;
  }
  resetBelow(fieldNames, "options_isIv", data, state);
}

function resetBelow(fieldNames, changedField, data, state) {
  let reset = false;

  fieldNames.forEach((field) => {
    if (reset) {
      // Reset boolean fields to false
      if (typeof state.formData[field] === "boolean") {
        state.formData[field] = false;
      }
      // Reset string fields to empty string
      if (typeof state.formData[field] === "string") {
        state.formData[field] = "";
      }
      // Reset fields that can be null
      if (state.formData[field] === null || typeof state.formData[field] === "undefined") {
        state.formData[field] = null;
      }
      // Rest array fields to empty array
      if (Array.isArray(state.formData[field])) {
        state.formData[field] = [];
      }
    }

    // If this is the changed field, start resetting subsequent fields
    if (field === changedField) {
      reset = true;

      // Apply the change for the current field
      state.formData[field] = data[field];
    }
  });
}
